import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import NewsMosaic from 'components/NewsMosaic'
import NewsList from 'components/NewsList'

export default function InsightsTemplate(props) {
  const [allCats, setAllCats] = useState([])
  const [allPosts, setAllPosts] = useState([])
  const [activeCatSlug, setActiveCatSlug] = useState([])

  const { data, pageContext, location } = props
  const { isIndustryNews } = pageContext
  const isSearch = location.pathname.split('/')[2] === 'search'

  useEffect(() => {
    if (location.state?.fromM1) {
      if (pageContext.catSlug && pageContext.catSlug !== '*') {
        const div = document.querySelector('#insights-filter')
        window.scrollTo({ top: div.offsetTop })
      }
    }

    let getAllCats = data.allWpCategory.nodes
    getAllCats = getAllCats.filter(
      (cat) => cat.name !== 'Uncategorized' && cat.slug !== 'industry-news'
    )
    let getAllPosts = isIndustryNews
      ? data.allPostsHidden.nodes
      : data.allPostsVisible.nodes
    getAllPosts = getAllPosts.filter(
      (post) => post.template.templateName !== 'Default'
    )
    let getActiveCatSlug =
      pageContext.catSlug === '*' ? '' : pageContext.catSlug
    if (getActiveCatSlug) {
      getAllPosts = getAllPosts.filter((post) =>
        post.categories.nodes.find(
          (category) => category.slug === getActiveCatSlug
        )
      )
    }
    setAllCats(getAllCats)
    setAllPosts(getAllPosts)
    setActiveCatSlug(getActiveCatSlug)
  }, [])
  let page = data.wpPage
  if (isIndustryNews) {
    page.seo.title = '行业新闻 - Analytic Partners勘讯咨询'
  }
  const { newsMosaic } = page

  const updateSearchKeyword = (e) => {
    const keyword = e.target.value.toLowerCase()
    let getAllPosts = data.allPostsVisible.nodes
    getAllPosts = getAllPosts.filter(
      (post) => post.template.templateName !== 'Default'
    )
    getAllPosts = getAllPosts.filter((post) =>
      post.title.toLowerCase().includes(keyword)
    )
    setAllPosts(getAllPosts)
  }

  return (
    <Layout
      bodyClassName='blog biggest-changes-marketing-measurement app-data index-data home-data'
      page={page}
    >
      {!isIndustryNews && <NewsMosaic content={newsMosaic} />}
      <NewsList
        allCats={allCats}
        allPosts={allPosts}
        activeCatSlug={activeCatSlug}
        pageContext={pageContext}
        isSearch={isSearch}
        updateSearchKeyword={updateSearchKeyword}
        isIndustryNews={isIndustryNews}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!, $catSlug: String!) {
    wpPage(slug: { eq: "insights" }) {
      title
      ...SeoPage
      ...BannerPage
      ...NewsMosaic
      ...RelatedBlockPage
      ...ContactBlockPage
    }
    allWpCategory(sort: { fields: description }) {
      nodes {
        name
        slug
      }
    }
    allPostsVisible: allWpPost(
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
      filter: {
        categories: {
          nodes: {
            elemMatch: { slug: { glob: $catSlug, nin: "industry-news" } }
          }
        }
      }
    ) {
      nodes {
        template {
          templateName
        }
        slug
        title
        ...FormattedPostDate
        postImage {
          image {
            ...GatsbyImage
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    allPostsHidden: allWpPost(
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "industry-news" } } } }
      }
    ) {
      nodes {
        template {
          templateName
        }
        slug
        title
        ...FormattedPostDate
        postImage {
          image {
            ...GatsbyImage
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`
