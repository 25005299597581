import React from 'react'
import LinkWrap from 'components/LinkWrap'
import ImageWrap from 'components/ImageWrap'
import { navigate } from 'gatsby'
import { strings } from 'js/strings'

const goTo = (to, scrollToNav = false) => {
  navigate(to, {
    state: {
      disableScrollUpdate: true,
    },
  })
  if (scrollToNav) {
    const div = document.querySelector('#insights-filter')
    window.scrollTo({ top: div.offsetTop })
  }
}

export default function NewsList({
  allCats,
  allPosts,
  activeCatSlug,
  pageContext,
  isSearch,
  updateSearchKeyword,
  isIndustryNews,
}) {
  return (
    <div className='insights' id='insights-filter'>
      <Nav
        allCats={allCats}
        activeCatSlug={activeCatSlug}
        isSearch={isSearch}
        isIndustryNews={isIndustryNews}
      />
      {isSearch && <SearchBar updateSearchKeyword={updateSearchKeyword} />}
      <List allPosts={allPosts} />
      {!isSearch && (
        <Pagination pageContext={pageContext} isIndustryNews={isIndustryNews} />
      )}
    </div>
  )
}

const Nav = ({ allCats, activeCatSlug, isSearch, isIndustryNews }) => {
  const searchActiveClass = isSearch ? 'insights__nav-item--active' : ''
  return (
    <div className='insights__nav-container'>
      <ul className='insights__nav'>
        <NavItem
          cat={{ name: '全部内容', slug: '' }}
          activeCatSlug={activeCatSlug}
          isSearch={isSearch}
        />
        {!isIndustryNews &&
          allCats.map((cat, i) => (
            <NavItem key={i} cat={cat} activeCatSlug={activeCatSlug} />
          ))}
        {!isIndustryNews && (
          <li className={`insights__nav-item js-search ${searchActiveClass}`}>
            <a
              className='insights__nav-item-link js-link'
              onClick={() => goTo('/insights/search/')}
            >
              <svg width='27' height='27' xmlns='http://www.w3.org/2000/svg'>
                <g
                  stroke='#fff'
                  strokeWidth='2'
                  fill='none'
                  fillRule='evenodd'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path d='M21.239 11.33c0 5.522-4.477 10-10 10s-10-4.478-10-10c0-5.523 4.477-10 10-10s10 4.477 10 10zM19.239 19.33l6 6'></path>
                </g>
              </svg>
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

const NavItem = ({ cat, activeCatSlug, isSearch }) => {
  if (cat.name === 'uncategorized') return false
  let activeClass = ''
  if (activeCatSlug === cat.slug && !isSearch)
    activeClass = 'insights__nav-item--active'

  let link = '/insights/'
  if (cat.slug) link += `${cat.slug}/`

  return (
    <li className={`insights__nav-item ${activeClass}`}>
      <div className='insights__nav-item-link' onClick={() => goTo(link)}>
        {cat.name}
      </div>
    </li>
  )
}

const SearchBar = ({ updateSearchKeyword }) => {
  return (
    <div className='insights__search-drawer insights__search-drawer--show'>
      <form
        className='insights__search-form'
        action='/insights'
        method='get'
        _lpchecked='1'
      >
        <label htmlFor='search-input'></label>
        <input
          name='s'
          type='text'
          placeholder={strings.search}
          id='search-input'
          className='insights__search-drawer-input'
          onKeyUp={updateSearchKeyword}
        />
      </form>
    </div>
  )
}

const List = ({ allPosts }) => {
  return (
    <div className='insights__results-container'>
      {allPosts.map((item, i) => (
        <ListItem key={i} content={item} />
      ))}
      {allPosts.length === 0 && (
        <div className='insights__no-results'>
          <h4>{strings.noPostsWereFound}</h4>
        </div>
      )}
    </div>
  )
}

const ListItem = ({ content }) => {
  const { categories, date, postImage, slug, title } = content
  const { image } = postImage
  const firstCategory = categories.nodes[0]
  const link = `/${firstCategory.slug}/${slug}/`

  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip image-content-pretitle-50-50'>
      <div className='image-content-pretitle-50-50__container multivariate-grid__container'>
        <div className='image-content-pretitle-50-50__row multivariate-grid__row'>
          <div className='image-content-pretitle-50-50__column multivariate-grid__column'>
            <LinkWrap
              className='image-content-pretitle-50-50__image-link'
              to={link}
            >
              <div className='image-content-pretitle-50-50__image-wrap'>
                {image && <ImageWrap image={image} />}
              </div>
            </LinkWrap>
          </div>
          <div className='image-content-pretitle-50-50__column multivariate-grid__column'>
            <div className='content-pretitle-card content-pretitle-card--minimal'>
              <p className='content-pretitle-card__pretitle'>
                {firstCategory.name}
              </p>
              <LinkWrap to={link}>
                <h3 className='content-pretitle-card__title'>{title}</h3>
              </LinkWrap>

              {(firstCategory.slug === 'blog-posts' ||
                firstCategory.slug === 'news-items') && (
                <p className='content-pretitle-card__pretitle--date'>{date}</p>
              )}

              <LinkWrap to={link} className='content-pretitle-card__cta'>
                {strings.readThe}
                {/* {firstCategory.name} */}
              </LinkWrap>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const Pagination = ({ pageContext, isIndustryNews }) => {
  const { catSlug, previousPagePath, nextPagePath, numberOfPages, pageNumber } =
    pageContext
  let pageLinks = []
  let addedDots = false
  const linkDir = isIndustryNews ? 'industry-news' : 'insights'
  for (let i = 0; i < numberOfPages; i++) {
    if (i < pageNumber - 1) continue
    if (i > pageNumber + 1 && i !== numberOfPages - 1) {
      if (!addedDots) {
        pageLinks.push({ isDots: true })
        addedDots = true
      }
      continue
    }
    const toExt = i === 0 ? '' : `${i + 1}/`
    const isActive = i === pageNumber
    const directory = catSlug === '*' ? '' : `${catSlug}/`
    pageLinks.push({
      text: i + 1,
      to: `/${linkDir}/${directory}${toExt}`,
      isActive,
    })
  }
  return (
    <div className='insights__pagination'>
      <nav className='navigation pagination' role='navigation'>
        <h2 className='screen-reader-text'>Posts navigation</h2>
        <div className='nav-links'>
          {previousPagePath && (
            <span
              onClick={() => goTo(previousPagePath + '/', true)}
              className='js-link prev page-numbers'
            >
              <span>‹ {strings.previous}</span>
            </span>
          )}
          {pageLinks.map((pageLink, i) => {
            if (pageLink.isDots) {
              return (
                <span key={i} className='page-numbers dots'>
                  ...
                </span>
              )
            }
            if (pageLink.isActive) {
              return (
                <span key={i} className='current page-numbers'>
                  {pageLink.text}
                </span>
              )
            }
            return (
              <span
                key={i}
                onClick={() => goTo(pageLink.to, true)}
                className='js-link page-numbers'
              >
                {pageLink.text}
              </span>
            )
          })}
          {nextPagePath && (
            <span
              onClick={() => goTo(nextPagePath + '/', true)}
              className='js-link next page-numbers'
            >
              <span>{strings.next} ›</span>
            </span>
          )}
        </div>
      </nav>
    </div>
  )
}
