import React from 'react'
import ImageWrap from 'components/ImageWrap'
import PageLink from 'components/PageLink'
import { isObjectEmpty } from 'js/utils'

export default function NewsMosaic({ content }) {
  const { left, right } = content
  const { block1, block2 } = left
  const { block3, block4 } = right

  let i = 0
  const blocks = [block1, block2, block3]
  blocks.forEach((block) => {
    if (!isObjectEmpty(block)) i++
  })

  if (i === 0) {
    // all blocks empty
    return <div />
  }

  return (
    <section className='multivariate-grid'>
      <div className='multivariate-grid__container'>
        <div className='multivariate-grid__row'>
          <section className='post-cluster   post-cluster--offset-top '>
            <div className='post-cluster__container'>
              <div className='post-cluster--container-4'>
                <div className='post-cluster__flex'>
                  <div className='post-cluster__left-content'>
                    <div className='post-cluster__left-content__inner'>
                      {!isObjectEmpty(block1) && (
                        <Block12
                          content={block1}
                          className='post-cluster__left-content-first'
                        />
                      )}
                      {!isObjectEmpty(block2) && (
                        <Block12
                          content={block2}
                          className='post-cluster__left-content-second'
                        />
                      )}
                    </div>
                  </div>
                  <div className='post-cluster__right-content'>
                    <div className='post-cluster__right-content__inner'>
                      {!isObjectEmpty(block3) && <Block3 content={block3} />}
                      {!isObjectEmpty(block4) && <Block4 content={block4} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

const Block12 = ({ content, className }) => {
  const { pageLink, externalUrl } = content
  if (externalUrl) {
    return (
      <div className={className}>
        <a href={externalUrl} target='_blank'>
          <Block12Inner content={content} />
        </a>
      </div>
    )
  } else {
    return (
      <div className={className}>
        <PageLink pageLink={pageLink}>
          <Block12Inner content={content} />
        </PageLink>
      </div>
    )
  }
}

const Block12Inner = ({ content }) => {
  const { pretitle, title, excerpt, pageLinkText } = content
  return (
    <article className='post '>
      <div className='post__content'>
        {pretitle && <p className='post__taxonomy'>{pretitle}</p>}
        <h4 className='post__title'>{title}</h4>
        {excerpt && <p className='post__excerpt'>{excerpt}</p>}
      </div>
      {pageLinkText && (
        <div className='post__cta'>
          <p className='post__cta-text'>{pageLinkText}</p>
        </div>
      )}
    </article>
  )
}

const Block3 = ({ content }) => {
  const { pageLink, externalUrl } = content
  if (externalUrl) {
    return (
      <div className='post-cluster__right-content-first'>
        <a href={externalUrl} target='_blank'>
          <Block3Inner content={content} />
        </a>
      </div>
    )
  } else {
    return (
      <div className='post-cluster__right-content-first'>
        <PageLink pageLink={pageLink}>
          <Block3Inner content={content} />
        </PageLink>
      </div>
    )
  }
}
const Block3Inner = ({ content }) => {
  const { pretitle, title, pageLink, pageLinkText } = content

  return (
    <article className='post'>
      <div className='post__content post__content--single'>
        {pretitle && <p className='post__taxonomy'>{pretitle}</p>}
        <h4 className='post__title'>{title}</h4>
      </div>
      {pageLinkText && (
        <div className='post__cta'>
          <p className='post__cta-text'>{pageLinkText}</p>
        </div>
      )}
    </article>
  )
}

const Block4 = ({ content }) => {
  const { pageLink, externalUrl } = content
  if (externalUrl) {
    return (
      <div className='post-cluster__right-content-second'>
        <a href={externalUrl} target='_blank'>
          <Block4Inner content={content} />
        </a>
      </div>
    )
  } else {
    return (
      <div className='post-cluster__right-content-second'>
        <PageLink pageLink={pageLink}>
          <Block4Inner content={content} />
        </PageLink>
      </div>
    )
  }
}
const Block4Inner = ({ content }) => {
  const { image, title, pageLinkText } = content
  return (
    <article className='post'>
      {image && (
        <div className='post__content post__content--has-bg-img'>
          <ImageWrap className='post__img' image={image} />
          <h4 className='post__title'>{title}</h4>
        </div>
      )}
      {pageLinkText && (
        <div className='post__cta'>
          <p className='post__cta-text'>{pageLinkText}</p>
        </div>
      )}
    </article>
  )
}
